import React from "react";
import "./privacy.css";

import Header from "../header/Header";
import Footer from "../footer/Footer";

function Privacy() {
  return (
    <div className="privacy">
      <Header />
      <div className="data-privacy">
        <h1>Privacy Policy</h1><br />
        <h2>Last updated June 2nd, 2023</h2>
        <hr /><br />
        
        This privacy notice lets you know how we safeguard your personal data
        and the measures we take to keep it safe. <br /><br />
        
        Here, you can find out what
        kind of information we use to provide you with our bundle of
        transportation services, how we use your information to keep you safe,
        and the choices and controls available to you. In this notice, it is
        also stated that all the possible rights you have regarding to the
        privacy of your data. The data protection rules are set up in order to
        protect everyone who participates in the work. The term “us” or “we”
        refers to <b>XOBO</b> company incorporated and registered under the laws of the
        Kenyan government. <br /><br />

        In compliance with data protection regulations, we are licensed Data Controllers responsible for overseeing inquiries related to this privacy notice and ensuring the security of your personal data. For additional information about our licensed Data Controllers, please consult our <a href="/xdl-docs/XDL - DATA CONTROLLER DOC.pdf" target="_blank" download={"XOBO DELIVERY LIMITED - DATA CONTROLLER CERTIFICATE.pdf"}>Data Controller Document</a>. <br /><br />
        
        The term "you" or "your" refers to people who go to
        our app service that is requesting, hiring, and/or receiving a
        transportation service. <br /><br />
        <hr />
        
        <h3>1. Personal data we process</h3>
        We only process the
        information that is necessary for your needs on our marketplace. This
        ensures that we give you the best possible service for what you are
        looking to achieve. <br /> <br />
        &nbsp;&nbsp;• Contact details: We will ask for your personal
        details like name, phone number, and e-mail address so that we can best
        help you. <br /> <br />
        &nbsp;&nbsp;• Profile information: We collect some data to help you build
        your profile, such as your saved addresses and language preferences.
        Some of our services require some additional details like your driver's
        license number. <br /> <br />
        &nbsp;&nbsp;• Geolocation: By accessing your location we are able to
        offer a list of services on your location, such as local ride options.
        it will tell you how long the journey would take, with different
        expenditure options. there are also hints about your destination and
        traffic conditions. <br />  <br />
        &nbsp;&nbsp;• Payment details: Information about the payment
        method used, like the amount charged and what card.  <br /> <br />
        &nbsp;&nbsp;• In-app
        communication records: We monitor how you interact with our app
        regularly to make sure we're providing top-tier service. We also keep a
        record of your in-app chats so that we can easily get back in touch if
        needed. <br />  <br />
        &nbsp;&nbsp;• The limited identification data: We collect the device IP on
        which the app has been installed. <br />  <br />
        &nbsp;&nbsp;• Ride data: The data that we are
        collecting about the usage of service such as journey time, the route
        taken, etc. These time stamps and statistics allow us for easy reference
        in support of scheduling and are valuable to ensure driver safety. <br />  <br />
        &nbsp;&nbsp;• In-App message data: Our in-app chat collects information regarding the
        date, time, and content of your communication with a driver. <br />  <br />
        &nbsp;&nbsp;• User image: For verification purposes, our app will store users' profile
        images as well. <br />  <br /> <br />
        
        <h4>Usage Data</h4> <br />
         Using the platform will automatically
        collect the data.  <br />
        
        Usage data is information about your device, browser,
        and website activity. It can tell us things like what type of device you
        are visiting from, the operating system, version / itunes version number
        of your browser (noted when available), pages you visit for the website
        that we provide customer service support for in addition to off-premise
        services like hardware software inventory management.  <br />
        
        When visiting our
        website and using the Mobile App, we may collect certain information
        automatically without any further human interaction. This includes but
        is not limited to IP address, device type, device ID number, your device
        operating system, network state, and your current location. Even when
        not using the app, the device will still fetch your location and provide
        ride request capability.  <br />
        We collect information about Your visits to our
        website or using our app, including any mobile device you use.  <br /> <br />
        
        <h4>Tracking Technologies and Cookies </h4> <br />
        
        We use Cookies, beacons, tags, and scripts to
        track the activity on Our Service and store certain information. These
        technologies allow us to collect and; analyze data for improved
        performance and better user experience Your internet browser allows you
        the option of telling it which type of web Cookies you do or don't
        accept. Without this capability, some parts of our website may not
        function properly.  <br />
        
        Cookies can be either "Persistent" or "Session"
        Cookies. The difference between the two is that Persistent Cookies
        remain on a personal computer or mobile device even when the user closes
        her web browser, while Session Cookies are deleted as soon as they are
        no longer in use.  <br />
        
        We use both session and persistent Cookies for the
        purposes set out below: <br /><br />
        
        <h4>Necessary / Essential Cookies</h4> <br />
        Type: Session Cookies  <br />
        Administered by: Us  <br />
        Purpose: Cookies are used to authenticate users and prevent fraud. They help you navigate through the website and
        use a few of the services available to you. Cookies allow Us to provide
        You with the services that You have requested, which is important for
        our business. Without them, We cannot provide these services and would
        have trouble doing so. <br /> <br />
        
        <h4>Cookies Policy / Notice Acceptance Cookies</h4> <br />
        
        Type: Persistent Cookies  <br />
        Administered by: Us  <br />
        Purpose: These cookies identify
        whether or not a visitor has accepted the website's use of cookies <br /> <br />

        <h4>Functionality Cookies</h4> <br />
        
        Type: Persistent Cookies <br />
        Administered by: Us <br />
        Purpose: Cookies allow you to make a personalized experience across our
        website. They help us remember your preferences, view your account
        details and more. These Cookies are used on the site to provide you with
        a more enjoyable experience and allow us to avoid having to ask for your
        preferences again.  <br /> <br />
        
        <h3>2.Purpose of the processing</h3>  <br />
        
        We process your personal data so we can
        provide you with the services you needed more efficiently:

        <p><b>• To connect you with a driver:</b></p>
        We collect personal data so we can connect with users
        who are looking to be picked up and dropped off. These services include
        cars, bikes, and scooters that are available in areas close by. The
        instant messages the app uses can be used for customer service and to
        resolve disputes between drivers and riders. This is also helpful when
        they use that data to improve their products and services, as well as
        analyze it.
        
        <p><b> • We help you keep track of your ride:</b></p>
        We help drivers
        congregate at specific locations and show them where the passengers they
        are picking up or dropping off are located. Drivers can also keep track
        of the route on their monitor to save time, effort, and make sure
        they have both their phone number and geolocation nearby so that they
        never miss a call or text from riders. This data is collected only when
        the app is activated (in use). Drivers in some countries cannot see the
        passenger's phone number on the app after the app has been closed. This
        is done to avoid personal information from being accessible to drivers.

        <p><b>• To make sure your smooth journey:</b></p>
        Geolocation data is used to
        automatically get employees to their destinations and reduce the loss of
        time incurred by getting someone in the wrong location. It also helps
        ensure billings are correct.

        <p><b>• We make sure the optimal app performance:</b></p>
        We use your contact information to notify you of any
        changes to our app. We appreciate you continuing to use our service, and
        these updates will help you make sure you are using the latest version
        of our service. We collect limited data from your device to improve our
        service (IP address and data that helps you connect with the internet).
        These checks help keep your account safe and verify your authentication.

        <p><b>• To make sure to offer a quick, cheap, and most convenient ride to
        you:</b></p>
        We collect data on the routes taken by our passengers and riders to
        analyze geographic coverage. This feature allows us to find the most
        efficient routes for drivers and our scooters. It also helps make sure
        that our employees are near customers.

        <p><b>• We collect your payment:</b></p>
        We obtain payment details to process passenger payments for drivers who use
        our ride-hailing app. These are usually used in transactions such as
        rideshare and cab.
        <p><b>• We maintain and promote standards:</b></p>
        We collect data on journeys, such as whether a journey has concluded, or when it
        happened. Through this, we encourage customer safety and compliance with
        our terms and conditions. We also want to make sure that everyone comes
        away from their experience with us feeling satisfied. Customer service
        is an important aspect of any business, and feedback on the quality of
        your customer support can be helpful. It allows you to resolve disputes,
        as well as instill a sense that your company cares about the customer’s
        experience.

        <p><b> • We keep you informed:</b></p>
        We'll use your name, phone number,
        and email address to let you know if your journey is complete or to send
        you other trip-related information.  <br /> <br />
        
        <h3>3. Legal Basis</h3> <br />        
        We are allowed to use
        your data in the ways described above if you have a proper reason for
        doing so. Your information always comes with an explicit need for you,
        and we make sure that any usage is justified.  <br /> <br />
        
        &nbsp;&nbsp;• We process your personal
        data to create the personalized service you want. Personal data may be
        processed to meet our terms and conditions, in order to give you an
        unparalleled service.  <br /> <br />
        &nbsp;&nbsp;• We confirm with you whether we need your
        personal data in order to process your request or if it falls under
        legitimate interest. Our legitimate interests include providing an
        innovative, personalized, and safe service to our passengers and
        partners. This is at the expense of other interests - including being
        profitable. Our legitimate interests in our company include things like
        investigating fraud and other malicious activity, maintaining the
        security of our systems, and responding to potential criminal activity. <br /> <br />

        We sometimes rely on alternative legal bases when:  <br /> <br />
        &nbsp;&nbsp;• Compliance with a
        legal obligation such as processing data when required can be
        beneficial. It happens, for example, if there is a valid legal request. <br /> <br />

        &nbsp;&nbsp;• In the event of an emergency or when there is a threat to life. We
        always strive for transparency in all of our contact with you. This
        includes requirements for providing consent to specific uses as well as
        information about how we review, use, and retain your content metadata.
        Getting personal information to complete a contract is important and may
        allow the company to avoid certain legal consequences. It might also
        mean that they are not able to provide you with services. When you
        download our App, we collect and process your personal data, such as
        location information, so that drivers can find you in a timely manner.
        Refusing to share your location will cause it to be unavailable for
        driver directions and when looking for reservations. We will not let you
        sign up or use your account if there's a reasonable suspicion that
        you're trying to get around the obligations.  <br /> <br />
        
        <h3>4. Recipients</h3><br />
        We only work
        with trusted partners and authorities. We always share what is necessary
        and nothing more. We know that data can be valuable, so we don't sell
        your personal information to companies that may use it against you.  <br />
        
        &nbsp;&nbsp;• When a ride-on is requested, the company shares your personal data with
        the driver. This includes all name, contact number, and location data,
        as well as other demographic information.<br /><br />
        &nbsp;&nbsp;• After availing the
        transportation service, your information will remain visible to the
        driver for 24-48 hours. This is important because it allows supervisors
        who may have an issue like something left in the car to contact their
        driver and get the issue resolved with that passenger. <br /><br />
        &nbsp;&nbsp;• Riders give
        up-to-date feedback on their rides using our app. The data is anonymous
        and made private between you and the company. So even if someone gives
        you a bad rating, they can't use your input against you or it will no
        longer be accurate.<br /> <br />
        &nbsp;&nbsp;• It is in some cases legally compelled to share
        information with external recipients, such as when a court orders us or
        when we cooperate with a data protection supervisory authority.<br /><br />
        &nbsp;&nbsp;• We
        often perform these responses when we have a good-faith belief that the
        response is required by law in that jurisdiction, when it furthers a
        public interest task, affects users in that jurisdiction, and is
        consistent with our Terms of Service. We have a strong legal basis to
        share the information and will document our decision by providing a
        justification. <br /><br />
        
        <h3>5. Security of Your Personal Data </h3><br />
        Data security is one of
        our top priorities, but your data may not be completely secure. As we
        strive to use industry-accepted means of data protection, keep in mind
        that no method of transmission over the internet or method of electronic
        storage would be 100% secure. 
        
        <h3>6. Children's Privacy</h3><br />
        &nbsp;&nbsp;• We do not address
        anyone under the age of 13. We do not knowingly collect personal
        information from anyone under the age of 13. If you are a parent or
        guardian and you realize that your child has provided us with personal
        data, please contact us. We are aware that We collected Personal Data
        from anyone under the age of 13 without verifying parental consent on
        Our servers. <br /><br />
        &nbsp;&nbsp;• If this happens, We remove the information from Our
        servers. Some of our services are only available to users over 13. In
        certain cases, this means we might not be able to provide certain
        features or functionality of the service to these younger users. If We
        are required by law to seek your parent's consent in order for us to
        collect and process any personal data, we will not be able to do so
        unless you give us their permission. <br /><br />
        
        <h3>7. Links to Other Websites</h3><br />
        &nbsp;&nbsp;• We may
        link to other websites that aren't operated by us. If you click on a
        third party link, you will be taken to that website's site. It’s
        important that you read the privacy policy for every site you visit. <br /><br />
        &nbsp;&nbsp;• We do not censor any 3rd party sites or the content on those sites. We have
        no control over and assume no responsibility for the content,
        protections, privacy policies, or practices of any third party site with
        which we are linked. <br /><br />
        
        <h3>8. Changes to this Privacy Policy</h3><br />
        &nbsp;&nbsp;• We will update
        our Privacy Policy from time to time. If a change is made, we'll notify
        You via the Forum or via other means. <br /><br />
        &nbsp;&nbsp;• Email or a prominent notice before
        the change takes effect will be sent to provide notification of what
        changes are coming, as well as providing an update with the "Last
        updated" date at the top of this Privacy Policy. <br /><br />
        &nbsp;&nbsp;• Visitors should
        periodically check the privacy policy for any changes that have been
        posted on this site. Changes to the privacy policy will remain in effect
        until a new copy is posted to this page. <br /><br />
        
        <h3>9. Your rights and controls</h3><br />
        &nbsp;&nbsp;• We want you to remain in control of your personal data. We provide you with
        the necessary controls from within the app to view each and every bit of
        information we collect on our users including their profile data and
        trip history. We've also got some in-app settings, such as marketing
        opt-ins, and clear cookie consent controls on our app. <br /><br />
        
        <i>Your right of access</i><br />
        &nbsp;&nbsp;• You have the right to ask for a copy of any personal
        information we've collected about you. We may not always provide all the
        information you request from us because we have to balance our rights
        against those of others. <br /><br />

        <i>Your right to rectification</i> <br />
        &nbsp;&nbsp;• You can get better assistance with all your personal data on the app. You also have
        the right to ask us to update or complete any of the records that you
        believe are incomplete. This way, you have an easier time getting
        everything booted up and running smoothly.<br /><br />

        <i>Your right to erasure</i><br />
        &nbsp;&nbsp;• You can request that your personal information that's stored on our servers
        be removed in certain circumstances<br /><br />

        <i>Your right to restriction of processing</i> <br />
        &nbsp;&nbsp;• You have the right to ask us to restrict the processing of
        your information in certain circumstances. For example, if you file a
        complaint with the company or if you would like to exercise legal rights
        (like claiming compensation for damages or your data has been hacked)<br /><br />

        <i>Your right to object to processing</i><br />
        &nbsp;&nbsp;• We're processing your data on the
        basis of legitimate interests. If you want to object, you can tell us
        that and ask for a person to review it.<br /><br />
        
        <h3>10. Retention</h3> <br />

        Our app stores users' data only for as long as it needs to comply with their policies.
        All categories of data (e.g., billing, location) are stored for
        different periods of time depending on the type (e.g., 6 weeks for
        billing data, 12 months for location data). <br />

        &nbsp;&nbsp;• After having an active
        account with our service, your personal data is stored with us for as
        long as you use our services. You can permanently deactivate your
        account or delete your personal data through the app as needed. If your
        account is closed, personal data will be deleted according to our
        retention schedules and rules.<br /><br />
        &nbsp;&nbsp;• Data on transportation services
        provided to passengers will be stored for three years after the last
        journey and; data on other accounting purposes will be stored for seven
        years after the last journey.<br /><br />
        &nbsp;&nbsp;• We maintain all necessary data for
        internal, legal, and regulatory requirements to ensure we're a compliant
        company<br /><br />
        &nbsp;&nbsp;• In case of payment claims, your personal information will be
        retained unless it is satisfied or the claim expires.<br /><br />
        &nbsp;&nbsp;• The personal journey history and usage data of any individual will be stored for 3
        years after which it will be rendered private by anonymizing the data.<br /><br />

        Information about You is stored securely and will be used only as needed
        for those purposes. We store the data necessary for our business
        specifically to the company’s purposes, which might change over time.
        The information will not be collected or shared with any other party. At
        times, We may need to retain your Data to comply with our legal
        obligations and enforce our agreements and policies. The Company will
        also retain User Data for internal analysis purposes. User data is
        retained for a shorter period of time except when it is used to
        strengthen the security or to improve the functionality of our service,
        or we're legally obligated to keep this data for longer periods. 
        
        <h3>Contact Us</h3>
        If you have any questions about this Privacy Policy, You can contact
        us: By email: <a href="mailto:management@xobo.co.ke">management@xobo.co.ke</a>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
